<template>

  <div id="page-user-list" v-show="loaded">
    <!-- Header -->
    <div class="flex justify-between flex-wrap items-center mb-base">
      <div>
        <v-select style="width: 280px; border-radius: 4px;" class="sm:mr-4 mr-0 bg-white" :clearable="false" label="name" v-model="position" :options="positionOptions"></v-select>
      </div>
        <!-- Add Button -->
        <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4 mb-5" @click="addItem" style="position: relative; z-index: 41002;" v-if="user.employee.nik == 49315029 || user.employee.nik == 2015090001">Add Item</vs-button>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/4 mb-base" v-for="item in itemsData" :key="item.nik" v-show="itemsData.length > 0">
        <vx-card style="height: 100%;">
          <div class="text-center" @click="$router.push({ name: 'employee-show', params: { id: encrypt(item.nik) } })">
            <h6 class="mb-1">{{ displayName(item.name) }}</h6>
            <small class="text-grey" style="letter-spacing: 1.2px;">{{ item.position.name }}</small>
          </div>

          <vs-avatar @click.prevent="$router.push({ name: 'employee-show', params: { id: encrypt(item.nik) } })" class="mx-auto my-6 block" size="130px" :src="$storageUser + '/' + item.user.picture" v-if="item.user" />
          <vs-avatar @click.prevent="$router.push({ name: 'employee-show', params: { id: encrypt(item.nik) } })" class="mx-auto my-6 block" size="130px" src="false" v-else />

          <div class="text-center">
            <h6 class="text-grey" style="letter-spacing: 1.5px;">{{item.nik}}</h6>
          </div>

          <vs-divider></vs-divider>

          <vs-button style="width: 100%; font-weight: 500;" icon-after type="border" color="success" @click="$router.push({ name: 'employee-show', params: { id: encrypt(item.nik) } })" icon-pack="feather" icon="icon-arrow-right-circle">
            Lihat Detail
          </vs-button>

        </vx-card>
      </div>
    </div>

    <!--  Popup Employee  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-3">
          <small class="mb-3">{{ $i18n.t('Role') }}</small>
          <v-select :options="positionOptions" v-model="form.id_jabatan" label="name"></v-select>
        </div>

        <div class="vx-col md:w-1/2">
          <vs-input class="w-full mb-3" :label="$i18n.t('NIK')" v-model="form.nik" />
        </div>

      </div>

      <div class="vx-row">
        <div class="vx-col" style="width: 100%">
          <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.name" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('KTP')" v-model="form.id_card_number" />
        </div>

        <div class="vx-col md:w-6/12">
          <small class="mb-1">{{ $i18n.t('Gender') }}</small>
          <ul class="center mt-1 mb-3" style="display: flex; ">
            <li class="mr-3">
              <vs-radio v-model="form.sex" vs-value="m">{{ $i18n.t('Male') }}</vs-radio>
            </li>
            <li>
              <vs-radio v-model="form.sex" vs-value="f">{{ $i18n.t('Female') }}</vs-radio>
            </li>
          </ul>
        </div>

        <div class="vx-col md:w-6/12">
          <label class="vs-input--label">{{$i18n.t('DateofBirth')}}</label>
          <datepicker format="d-MM-yyyy" :value="form.date_birth" @input="form.date_birth = $moment($event)"></datepicker>
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('PlaceofBirth')" v-model="form.place_birth" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('Email')" v-model="form.email" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('Phone')" v-model="form.phone" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('City')" v-model="form.city" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('PostalCode')" v-model="form.postal_code" />
        </div>

        <div class="vx-col md:w-1/1 mb-3 w-full">
          <vs-input :label="$i18n.t('Photo')" type="file" @change="handleFileChange($event.target.name, $event.target.files)" name="foto" class="w-full" />
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <label for="" class="vs-input--label">{{$i18n.t('Address')}}</label>
          <vs-textarea rows="6" class="w-full mb-3" v-model="form.address" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      form:{
        id_jabatan:[]
      },
      position:'All Position',
      positionOptions: [],
      mode: '',
      itemsData: [],
      employees: [],

      // AgGrid
      popup: false,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Name'),
          field: 'name',
          filter: true,
          width: 170,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellClass: 'text-center'
        },
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    displayName (name) {
      let names = name.split(' ');

      if (names.length >= 3) {
        name = names[0] + ' ' + names[1] + ' ' + names[2][0];
      } else {
        name = names.join(' ');
      }

      return name;
    },
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    encrypt(id) {
      return this.$secure.encrypt(id);
    },

    handleFileChange(name, src){

    },

    // checkbox onchange value
    changeVal(position_id){
      if(this.form.id_jabatan.length > 0){
        this.form.id_jabatan.splice(0, this.form.id_jabatan.length)
        this.form.id_jabatan.push(position_id)
      }
    },

    getData(){
      axios.get('/employees')
        .then(({data}) => {
          this.itemsData = data.values
          this.employees = data.values
        })
      .catch(err => {
        this.$vs.notify({
          title:'Oops something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },

    // trigger modal add
    addItem(){
      this.popup = true;
      this.mode = 'add';
    },

    // store an item
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          name: this.form.name,
          nik: this.form.nik,
          id_jabatan: this.form.id_jabatan.id,
          place_birth: this.form.place_birth,
          date_birth: this.form.date_birth.split('-').reverse().join('-'),
          sex: this.form.sex == 'm' ? 1 : 0,
          postal_code: this.form.postal_code,
          city: this.form.city,
          phone: this.form.phone,
          email: this.form.email,
          foto: this.form.foto,
          address: this.form.address,
          id_card_number: this.form.id_card_number,
        };

        axios.post('/employees', body).then(resp =>   {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = '';
    },

    getPositions(){
      axios.get(`/positions`)
      .then(resp => {
        this.positionOptions = resp.data.values

        // sort by name
        this.positionOptions.sort(function(a, b) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        this.positionOptions.unshift({id: 99, name: 'All Position'})
      })
      .catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  watch: {
    popup() {
      this.form = {};
    },

    position(position){
      if(position.name === 'All Position'){
        this.getData();
      }
      else{
        this.itemsData = this.employees.filter(employee => employee.id_jabatan === position.id)
      }
    }
  },
  created () {
    this.loading();
    this.getData();
    // get positions
    this.getPositions();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
